<div class="p-2">
    <div class="w-full h-40 rounded-lg bg-blue-100 p-2">
        <div class="flex flex-col justify-between h-full">

            <div class="flex flex-col">
                <div class="text-blue-500 font-bold">{{product?.name}}</div>
                @if (product?.barcodes?.length) {
                <div>
                    @for(barcode of product?.barcodes; track barcode.code) {
                    <span class="text-xs text-gray-400">{{barcode.code}}</span>
                    }
                </div>
                }
                @if (product?.tags?.length) {
                <div>
                    @for (tag of product?.tags; track tag.name) {
                    <span class="bg-yellow-200 rounded-md px-1 inline-block mr-2">#{{tag.name}}&nbsp;</span>
                    }
                </div>
                }
            </div>

            <app-price-list [prices]="prices" />

        </div>
    </div>

    @if (!product?.barcodes?.length) {
    <div class="flex flex-col items-center bg-purple-300 text-purple-600 rounded-lg mt-1">
        <span class="text-sm p-2">Aggiungi il codice a barre del prodotto per sbloccare altre funzionalità 🚀</span>
        <div class="flex flex-row justify-between items-center w-full px-2" *ngIf="barcodeInput === 'manual'">
            <form class="flex flex-row justify-around p-2 text-sm">
                <input type="text" class="border border-gray-500 text-gray-900" [formControl]="barcode" />
                <input type="submit" value="aggiungi"
                    class="rounded-r-md bg-purple-600 text-white p-1 px-2 uppercase font-bold" (click)="addBarcode()" />
            </form>
            <span (click)="barcodeInput='none'">✖</span>
        </div>
        <div class="flex flex-row justify-around w-full mb-2" *ngIf="barcodeInput === 'none'">
            <div class="px-3 rounded-xl bg-purple-600 text-white" (click)="barcodeInput='manual'">manuale</div>
            <div class="px-3 rounded-xl bg-purple-600 text-white" (click)="scanBarcode()" [hidden]="barcodeInput !== 'none'">scansiona
            </div>
        </div>
    </div>
    }
    
    <div>etichette</div>
    <div>
        <form class="flex flex-row justify-around p-2">
            <input type="text" class="border border-gray-500 text-gray-900" [formControl]="tagName" />
            <input type="submit" value="aggiungi" class="rounded-md bg-blue-400 text-blue-900 p-1 px-2 uppercase font-bold"
                (click)="addTag()" />
        </form>
    </div>
    <div>prezzi</div>
    <div>
        <form class="flex flex-row justify-around p-2">
            <input type="number" class="border border-gray-500 text-gray-900" [formControl]="price" />
            <select [formControl]="brand">
                @for(brand of brands; track brand.code) {
                <option value="{{brand.code}}" [selected]="brandSelected == brand.code">{{brand.code}}</option>
                }
            </select>
            <input type="submit" value="aggiungi" class="rounded-md bg-blue-400 text-blue-900 p-1 px-2 uppercase font-bold"
                (click)="addPrice()" />
        </form>
    </div>
</div>