import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { initProduct, initProductPrice } from './database/indexed-db';
import { Product, ProductPrice, Store } from './model/models';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private db: NgxIndexedDBService) { }

  addProduct(product: Product): Promise<Product> {
    return new Promise((resolve, reject) => {
      this.db.add('products', {name: product.name}).subscribe((key) => {
        product.id = key.id;
        resolve(product)
      });
    });
  }

  getProduct(id: number): Promise<Product> {
    return new Promise((resolve, reject) => {
      this.db.getByID('products', id).subscribe((product: any) => {
        resolve(initProduct(product));
      })
    });
  }

  getProducts(): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      this.db.getAll('products').subscribe((products: any[]) => {
        resolve(products.map((rec:any) => initProduct(rec)))
      })
    })
  }

  updateProduct(product: Product): Promise<Product> {
    return new Promise(resolve => {
      this.db.update('products', product).subscribe((product:any) => resolve(initProduct(product)));
    })
  }

  addProductPrice(product: Product, price: ProductPrice): Promise<ProductPrice> {
    return new Promise(resolve => {
      this.db.update('product_prices', {...price, product_id: product.id}).subscribe((price:any) => resolve(initProductPrice(price)));
    })
  }

  getProductPrices(product: Product): Promise<ProductPrice[]> {
    return new Promise(resolve => {
      this.db.getAllByIndex('product_prices', 'product_id', IDBKeyRange.only(product.id))
        .subscribe(prices => resolve(prices.map(i => initProductPrice(i))))
    })
  }

  deleteProductPrice(product: Product, price: ProductPrice): Promise<ProductPrice> {
    return new Promise(resolve => {
      this.db.getAllByIndex('product_prices', 'product_id', IDBKeyRange.only(product.id)).subscribe((prices:any[]) => {
        const item:any = prices.find((p) => ((p.store_id === price.store_id) && (p.date === price.date)));
        if (!!item) {
          this.db.delete('product_prices', item.id).subscribe(() => resolve(price))
        }
      });
    })
  }

  getBrands(): Promise<Store[]> {
    return new Promise(resolve => resolve([
      {code: 'conad'},
      {code: 'deco'},
      {code: 'md'},
    ]))
  }
}
