import { DBConfig } from "ngx-indexed-db";
import { Product, ProductPrice } from "../model/models";

export function migrationFactory() {
  // The animal table was added with version 2 but none of the existing tables or data needed
  // to be modified so a migrator for that version is not included.
  return {
    4: (_db: any, transaction: any) => {
      const store = transaction.objectStore('product_prices');
      store.createIndex('product_id', 'product_id', { unique: false });
    },
  }
}

export const dbConfig: DBConfig  = {
  name: 'alfredo-db',
  version: 6,
  objectStoresMeta: [{
    store: 'products',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: false } },
      { name: 'tags', keypath: 'tags', options: { unique: false } },
      { name: 'barcodes', keypath: 'barcodes', options: { unique: false } }
    ]
  },{
    store: 'product_prices',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'product_id', keypath: 'product_id', options: { unique: false } },
      { name: 'date', keypath: 'date', options: { unique: false } },
      { name: 'brand', keypath: 'brand', options: {unique: false} },
      { name: 'price', keypath: 'price', options: { unique: false } },
    ]
  }]
};

export const initProduct = (product: any): Product => {
  return {
    id: product.id || null,
    name: product.name || '',
    tags: product.tags || [],
    barcodes: product.barcodes || []
  }
}

export const initProductPrice = (price: any): ProductPrice => {
  return {
    id: price.id || null,
    date: price.date || '',
    brand: price.brand || '',
    price: price.price || ''
  }
}