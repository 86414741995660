import { NgModule } from '@angular/core';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { DataService } from './data.service';
import { dbConfig } from './database/indexed-db';

@NgModule({
  providers: [DataService],
  imports: [
    NgxIndexedDBModule.forRoot(dbConfig)
  ],
})
export class DataModule {}