import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScannerService {
  private stopped = true;
  private mStream: any;

  private scanned$: Subject<string> = new Subject();

  public enabled$: Subject<boolean> = new Subject();

  startScan(): Promise<Observable<string>> {
    this.stopped = false;
    this.enabled$.next(true);

    return new Promise((resolve, reject) => {
      this.mediaStream()
        .then((mediaStream) => {
          const video = document.querySelector("video");
          if (!video) {
            return;
          }

          this.mStream = mediaStream;

          video.srcObject = mediaStream;
          video.onloadedmetadata = () => {
            video.play();
            this.detectBarcode(video); 
          };
          resolve(this.scanned$);
        })
        .catch((err) => {
          // always check for errors at the end.
          console.error(`${err.name}: ${err.message}`);
          reject(err)
        });
    });
  }

  stopScan() {
    this.stopped = true;
    this.enabled$.next(false);

    const video = document.querySelector('video');
    if (!video) {
      return;
    }
    video.pause();
    this.mStream.getTracks().forEach((track:any) => {
      if (track.readyState == 'live') {
        track.stop();
      }
    });
    video.srcObject = null;
  }

  async detectBarcode(video: HTMLVideoElement) {
    // Create a BarcodeDetector for simple retail operations.
    const barcodeDetector = new BarcodeDetector({ formats: ["ean_13", "ean_8", "upc_a", "upc_e"] });

    // Let's scan barcodes forever
    while(!this.stopped && !!video.srcObject) {
      // Try to detect barcodes in the current video frame.
      var barcodes = await barcodeDetector.detect(video);

      // Continue loop if no barcode was found.
      if (barcodes.length == 0)
      {
        // Scan interval 50 ms like in other barcode scanner demos.
        // The higher the interval the longer the battery lasts.
        await new Promise(r => setTimeout(r, 150));
        continue;
      }

      this.scanned$.next(barcodes[0].rawValue);
      
      // Notify user that a barcode has been found.
      navigator.vibrate(200);

      this.stopScan();
    }
  }

  private mediaStream() {
    // Prefer camera resolution nearest to 1280x720.
    const constraints = {
      audio: false,
      video: { facingMode: 'environment', width: 1280, height: 720 },
    };

    return navigator.mediaDevices
      .getUserMedia(constraints);
  }
}
