import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductPrice } from '../../../data/model/models';

@Component({
  selector: 'app-price-list',
  standalone: true,
  imports: [],
  templateUrl: './price-list.component.html',
  styleUrl: './price-list.component.scss'
})
export class PriceListComponent implements OnChanges {
  
  @Input()
  prices: ProductPrice[] = [];

  _prices: ProductPrice[] = [];
  
  ngOnChanges(changes: SimpleChanges): void {
    this._prices = this.filter(this.prices)
  }
  
  public bestPrice = 0;

  filter(prices: ProductPrice[]): ProductPrice[] {
    if (prices.length === 0) {
      return prices;
    }

    const _prices = prices.reverse();
    this.bestPrice = Math.min(..._prices.map(i => i.price));
    const best = _prices.find(i => i.price = this.bestPrice);
    
    if (!best) {
      return _prices;
    }


    return [best, ..._prices.filter(i => i.date !== best.date)]; 
  }
}
