import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DataModule } from './data/data.module';
import { ScannerService } from './data/support/scanner.service';

@Component({
  selector: 'app-root',
    standalone: true,
  imports: [CommonModule, RouterOutlet, DataModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'alfredo';

  public scanEnabled = false;

  constructor(private scanner: ScannerService) {}

  ngOnInit(): void {
    this.scanner.enabled$.subscribe(enabled => {
      this.scanEnabled = enabled
    });
  }

  stopScanBarcode() {
    this.scanner.stopScan();
  }
}
