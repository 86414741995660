import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

import * as Sentry from "@sentry/angular";

Sentry.init({
  dsn: "https://9dbfe90eec24bb40640935d966c348d1@o4508052847001600.ingest.de.sentry.io/4508052848771152",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
});

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
