import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { DataModule } from '../../data/data.module';
import { DataService } from '../../data/data.service';
import { initProduct } from '../../data/database/indexed-db';
import { Product } from '../../data/model/models';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [ReactiveFormsModule, DataModule, RouterLink],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements OnInit {
  public productName = new FormControl('');
  public products: Product[] = [];

  public addProductSwitch = false;

  constructor(private dataService: DataService, private router: Router) {}

  ngOnInit(): void {
    this.loadProducts();
  }
  
  addProduct(): boolean {
    if (!this.productName?.value) {
      return false;
    }

    this.dataService.addProduct(initProduct({name: this.productName.value}))
      .then(() => {
        this.loadProducts();
        this.productName.setValue('');
      });

    return false;
  }

  getProduct(productId?: number): boolean {
    this.router.navigate(['/product', productId]);

    return false;
  }

  private loadProducts(): void {
    this.dataService.getProducts().then((products) => this.products = products.reverse())
  }
}
