<div class="p-2 flex flex-col justify-between h-screen">    
    <div class="flex flex-col flex-grow justify-around items-center">
        <div class="rounded-lg bg-blue-500 text-blue-300 uppercase bold px-2" [hidden]="addProductSwitch" (click)="addProductSwitch=true">Aggiungi prodotto</div>
        <div [hidden]="!addProductSwitch">
            <form class="flex flex-row justify-around p-2">
                <input type="text" class="border border-gray-500 text-gray-900" [formControl]="productName" />
                <input type="submit" value="aggiungi" class="rounded-md bg-blue-400 text-blue-900 p-1 px-2 uppercase font-bold"
                    (click)="addProduct()" />
            </form>
            <div class="rounded-r-full rounded-l-full bg-gray-200 text-gray-400 px-2" (click)="addProductSwitch=false">chiudi</div>
        </div>
        <div class="rounded-lg bg-blue-500 text-blue-300 uppercase bold px-2">Aggiungi prodotto</div>
        <div class="rounded-lg bg-blue-500 text-blue-300 uppercase bold px-2">Aggiungi prodotto</div>
        
    </div>
    <div class="product-container">
        @for (product of products; track product.name) {
        <a class="product w-40 h-40 rounded-lg bg-blue-100 p-2" (click)="getProduct(product.id)">
            <div class="text-blue-500 font-bold">{{product.name}}</div>
        </a>
        }
    </div>
</div>