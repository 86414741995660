import { CommonModule } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataModule } from '../../data/data.module';
import { DataService } from '../../data/data.service';
import { Product, ProductPrice, Store } from '../../data/model/models';
import { ScannerService } from '../../data/support/scanner.service';
import { PriceListComponent } from "../widget/price-list/price-list.component";

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DataModule, PriceListComponent],
  templateUrl: './product.component.html',
  styleUrl: './product.component.scss'
})
export class ProductComponent implements OnInit {
  public tagName = new FormControl('');
  public barcode = new FormControl('');
  public brand = new FormControl('');

  public scanEnabled:boolean = false;
  
  public price = new FormControl('');
  
  public productId: number = 0;
  public product?: Product;
  public prices: ProductPrice[] = [];
  public bestPrice = 0;
  public brands: Store[] = [];
  public brandSelected: string = '';

  public barcodeInput = 'none';

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private scanner: ScannerService,
    private zone: NgZone
  ) {}
  
  ngOnInit(): void {
    this.route.params.subscribe(params => this.init(params['id']));
  }

  private init(id: number): void {
    this.productId = +id;
    this.loadProduct().then((product) => this.loadProductPrices(product));
    this.loadBrands();
  }
  
  addTag(): boolean {
    if (!this.tagName.value || !this.product) {
      return false;
    }

    this.product?.tags?.push({name: this.tagName.value});
    this.dataService.updateProduct(this.product);
    this.tagName.setValue('');

    return false;
  }

  addPrice(): boolean {
    if (!this.price.value || !this.product) {
      return false;
    }

    const price = {price: +(this.price.value || 0.0)*1.0, date: new Date().toISOString(), brand: this.brand.value || ''};
    this.prices = [...this.prices, price];
    this.dataService.addProductPrice(this.product, price);
    this.price.setValue('');

    return false;
  }

  deletePrice(price: ProductPrice): boolean {
    if (!this.product) {
      return false;
    }

    this.dataService.deleteProductPrice(this.product, price).then(() => this.loadProductPrices(this.product));
    
    return false;
  }

  addBarcode(): boolean {
    if (!this.barcode.value || !this.product) {
      return false;
    }

    this.product?.barcodes?.push({code: this.barcode.value});
    this.dataService.updateProduct(this.product);
    this.barcode.setValue('');

    return false;
  }

  onScanSuccess(result: string) {
    this.stopScan()
    if (!this.product || !result) {
      return;
    }

    this.product.barcodes.push({code: result});
    this.dataService.updateProduct(this.product);
  }

  onScanComplete() {
    this.stopScan()
  }

  scanBarcode() {
    this.startScan();
  }

  stopScanBarcode() {
    this.stopScan();
  }

  private loadProduct(): Promise<Product> {
    return new Promise(resolve => {
      this.dataService.getProduct(this.productId).then((product) => {
        this.product=product;
        resolve(this.product);
      });
    });
  }

  private loadBrands(): void
  {
    this.brandSelected = 'deco';
    this.dataService.getBrands().then(brands => this.brands = brands);
  }

  private loadProductPrices(product?: Product) {
    if (!product) {
      return;
    }

    this.dataService.getProductPrices(product)
      .then((prices: ProductPrice[]) => this.prices = [...prices])
  }

  // scanner

  private startScan() {
    this.scanner.startScan().then(scanned$ => scanned$.subscribe(scanned => this.zone.run(() => this.onScanSuccess(scanned))))
    this.scanEnabled = true;
  }

  private stopScan() {
    this.scanEnabled = false;
    this.scanner.stopScan();
  }
}
