<div class="flex flex-nowrap overflow-x-auto">
    @for(price of _prices; track price.date) {
    <div class="price">
        <div>
            <img [src]="'/stores/32x32/segnalino_'+(price.brand||'deco')+'.png'" class="w-8 h-8 rounded-full" />
        </div>
        <div>
            <div>{{price.brand}}</div>
            <div>04-10</div>
        </div>
        <div class="flex flex-col justify-center items-center">
            <div class="price-value" [class.best]="bestPrice==price.price">{{price.price}}€</div>
        </div>
    </div>
    }
</div>